import { getActivitiesByCode } from '@/api/activity.js'
import { MYY_ACTIVITY_CODE } from '@/settings'

import { mapGetters } from 'vuex'
const activityMixin = {
    data() {
        return {
            loadingActivity: false
        }
    },
    computed: {
        ...mapGetters(['activity', 'ageGate'])
    },
    async created() {
        try {
            if (this.$route.query['r'] || this.$route.query['agegate']) {
                this.$store.dispatch('setAgeGate', true)
                this.$store.dispatch('setRetailer', this.$route.query.r)
            }
            if (!this.activity._id && !this.loadingActivity) {
                this.loadingActivity = true
                const response = await getActivitiesByCode(MYY_ACTIVITY_CODE)
                if (response.total >= 1) {
                    this.$store.dispatch('setActivity', response.data[0])
                } else {
                    throw new Error('Activity Not found.')
                }
            }
            this.loadingActivity = false
        } catch (error) {
            this.loadingActivity = false

            this.$message({
                type: 'error',
                message:
                    error.data ||
                    'An error occur. Please refresh the page or try again later.'
            })
        }
    }
}

export { activityMixin }
