<template>
  <div id="app" class="d-flex h-100 flex-column">
    <router-view />
  </div>
</template>
<script>
export default {
    watch: {
        $route(to) {
            document.title = 'Dive into the Deli Woolworths Promotion 2022 - ' + to.name || ''
            document['og:image'] = window.location.origin + '/img/social.jpg'
        }
    }
}
</script>
<style></style>
